import styled from "styled-components";

const FAQPageContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 1.5em;
    h2{
        text-align: center;
        font-size: 22px;
        color: #FFFFFF;
        border-radius: 150px;
        padding: 1em 0;
        margin-bottom: 1.25em;
    }
    p{
        padding: 1em;
        font-size: 17px;
        margin: 0;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        padding: 0 1em;
        h2{
            font-size: 17px;
            margin-bottom: 0;
        }
        p{
            font-size: 15px;
            padding-bottom: 16px;
            text-align: justify;
        }
    }
`;

const LeftSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 43%;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`;

const RightSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 43%;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`;

function FAQPage() {
    return (
        <FAQPageContainer>
            <LeftSideContainer>
                <h2 style={{ backgroundColor: "#813FBB" }}>Qual é a quantidade mínima para realizar encomendas?</h2>
                <p>Na nossa gráfica express, não há uma quantidade mínima para encomendas. Produzimos conforme a sua demanda, atendendo às suas necessidades específicas,
                    seja uma peça única ou grandes quantidades!</p>
                <h2 style={{ backgroundColor: "#0584FA" }}>Qual é o prazo para a entrega das encomendas?</h2>
                <p>O prazo de entrega varia conforme a quantidade encomendada. Em geral, pode levar de 3 a 10 dias úteis. Entre em contato conosco para um prazo mais
                    preciso de acordo com o seu pedido!</p>
                <h2 style={{ backgroundColor: "#FFDB49" }}>Como faço para solicitar um orçamento?</h2>
                <p>Para solicitar um orçamento, entre em contato conosco pelos nossos canais de comunicação. Estamos prontos para atender suas necessidades e
                    fornecer as informações necessárias. Nossa equipe ficará feliz em ajudar!</p>
                <h2 style={{ backgroundColor: "#FF54C6" }}>É possível fazer alterações no meu pedido após a confirmação?</h2>
                <p>As alterações podem ser feitas antes da impressão. Entre em contato o mais rápido possível para discutir as mudanças. Após a confirmação e início da
                    produção, não será possível realizar alterações.</p>
                <h2 style={{ backgroundColor: "#FCA34A" }}>Quais são os cuidados para manter produtos impressos em bom estado?</h2>
                <p>Para canecas, evite temperaturas extremas e lave à mão sempre que possível. Para camisetas, siga as instruções de lavagem e evite secadoras
                    para preservar a estampa. Outros produtos podem ter cuidados específicos, consulte-nos!</p>
            </LeftSideContainer>
            <RightSideContainer>
                <h2 style={{ backgroundColor: "#D9ED6B" }}>Como funcionam as encomendas de grandes quantidades?</h2>
                <p>Para encomendas em grandes quantidades, oferecemos descontos especiais. Entre em contato para discutir seu pedido e receber uma cotação personalizada.</p>
                <h2 style={{ backgroundColor: "#813FBB" }}>Criam arte para canecas, camisetas, banners, cartões de visita e outros itens?</h2>
                <p>Sim, nós criamos artes personalizadas para uma variedade de produtos, incluindo canecas, camisetas, banners, cartões de visita e muito mais!
                    Nossa equipe de designers está pronta para transformar suas ideias em criações exclusivas. Entre em contato para discutir seu projeto!</p>
                <h2 style={{ backgroundColor: "#0584FA" }}>Quais as formas de pagamento vocês aceitam?</h2>
                <p>Aceitamos diversas formas de pagamento, incluindo PIX, cartão de crédito, débito e dinheiro. Escolha a opção que melhor se adequa a você!</p>
                <h2 style={{ backgroundColor: "#FFDB49" }}>Em quais lugares posso contatá-los para realizar encomendas?</h2>
                <p>Você pode entrar em contato conosco para realizar encomendas através de várias plataformas: WhatsApp, Instagram, e-mail ou telefone. Estamos prontos
                    para atender você da melhor forma!</p>
                <h2 style={{ backgroundColor: "#FF54C6" }}>Vocês oferecem entrega para diferentes regiões da cidade de Pelotas?</h2>
                <p>Sim, fazemos entregas para diversas regiões. Verifique nossa política de entrega no site ou entre em contato para saber mais sobre as áreas atendidas
                    e prazos de entrega.</p>
            </RightSideContainer>
        </FAQPageContainer>
    );
}

export default FAQPage;