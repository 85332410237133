import styled from 'styled-components';
import productsBanner from '../assets/images/products-banner.png';
import mobileProductsBanner from '../assets/images/products-mobile-banner.png';

const ProductsPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-bottom: 4em;

    @media screen and (max-width: 1024px){
        content: url(${mobileProductsBanner});
        width: 80%;
        padding: 2em;
    }
`;

function ProductsPage() {
    return (
        <ProductsPageContainer>
            <img src={productsBanner} alt='' />
        </ProductsPageContainer>
    );
}

export default ProductsPage;