import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import { register } from 'swiper/element/bundle';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import HomePage from './routes/HomePage';
import ContactPage from './routes/ContactPage';
import AboutPage from './routes/AboutPage';
import BestSellingPage from './routes/BestSellingPage';
import FAQPage from './routes/FAQPage';

import Header from './components/Header';
import Footer from './components/Footer';
import ProductsPage from './routes/ProductsPage';
import Navigation from './components/Navigation';
import WhatsappIcon from './components/WhatsappIcon';
import CreateSite from './components/CreateSite';


register();

const GlobalStyle = createGlobalStyle`
  body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  list-style: none;
}
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <BrowserRouter>
      <Header />
      <Navigation />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/contatos' element={<ContactPage />} />
        <Route path='/sobre' element={<AboutPage />} />
        <Route path='/produtos' element={<ProductsPage />} />
        <Route path='/mais-vendidos' element={<BestSellingPage />} />
        <Route path='/faq' element={<FAQPage />} />
      </Routes>
      <WhatsappIcon />
      <CreateSite />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);