import styled from "styled-components";

import graficaImage from '../assets/images/about-grafica-img.png';
import mugImage from '../assets/images/about-mug-img.png';
import logoImage from '../assets/images/about-logo-img.png';
import marcioImage from '../assets/images/about-marcio-img.png';
import danieleImage from '../assets/images/about-daniele-img.png';

import mapImage from '../assets/images/about-map.png';
import mapMobileImage from '../assets/images/about-mobile-map.png';

const AboutPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    h2{
        font-size: 40px;
        background: linear-gradient(90deg, #00012C, #4B0085, #EB1CD3);
        -webkit-background-clip: text;
        color: transparent;
    }
    p{
        font-size: 18px;
        text-align: justify;
    }

    @media screen and (max-width: 1024px) {
        h2{
            font-size: 32px;
        }
    }
`;

const TopContainer = styled.div`
    display: flex;
    padding: 0 10em;
    flex-wrap: nowrap;
    align-items: center;
    gap: 36px;

    @media screen and (max-width: 1024px) {
        gap: 0;
        padding: 0 1em;
        flex-direction: column;
        text-align: center;
    }
`;

const TopImagesContainer = styled.div`
    display: flex;
    justify-content: right;

    @media screen and (max-width: 1024px) {
        justify-content: center;
    }
`;

const MiddleContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10em;

    img {
        display: flex;
        width: 90%;
    }

    @media screen and (max-width: 1024px){
        padding: 0;
        align-items: center;
        text-align: center;
        h2{
            padding: 0 0.5em;
        }
        img {
            content: url(${mapMobileImage});
            width: 70%;
        }
    }
`;

const BottomContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding: 2em 0;
    img{
        width: 30%;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        img{
            width: 80%;
        }
    }
`;

function AboutPage() {
    return (
        <AboutPageContainer>
            <TopContainer>
                <div>
                    <img src={graficaImage} alt='' />
                </div>
                <div>
                    <h2>AQUI SUA IDEIA VIRA REALIDADE</h2>
                    <p>A MG-TEK foi fundada durante a pandemia, superando desafios e se destacando no mercado com soluções personalizadas de alta qualidade.
                        Nossa missão é conectar histórias e ideias por meio da impressão, sempre com cuidado e dedicação. Orgulhamo-nos de atender tanto pequenas
                        empresas quanto grandes corporações, acreditando que cada impressão é uma oportunidade de criar algo especial.</p>
                    <TopImagesContainer>
                        <img src={mugImage} alt='' />
                        <img src={logoImage} alt='' />
                    </TopImagesContainer>
                </div>
            </TopContainer>
            <MiddleContainer>
                <h2>ONDE NOS ENCONTRAR</h2>
                {/* <Mapa /> */}
                <img src={mapImage} alt="" />
            </MiddleContainer>
            <BottomContainer>
                <img src={marcioImage} alt='' />
                <img src={danieleImage} alt='' />
            </BottomContainer>
        </AboutPageContainer>
    );
}

export default AboutPage;