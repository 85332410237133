import styled from 'styled-components';

import instagramLogo from '../../assets/images/footer-instagram-logo.png';
import facebookLogo from '../../assets/images/footer-facebook-logo.png';
import whatsappLogo from '../../assets/images/footer-whatsapp-logo.png';
import emailLogo from '../../assets/images/footer-email-logo.png';

import mgtekLogo from '../../assets/images/footer-mgtek-logo.png';
import fusionLogo from '../../assets/images/footer-fusion-logo-img.png';

import miniWhatsapp from '../../assets/images/footer-mini-whatsapp-img.png';
import miniInstagram from '../../assets/images/footer-mini-instagram-img.png';
import miniEmail from '../../assets/images/footer-mini-email-img.png';

import pagamentoImage from '../../assets/images/footer-pagamento-img.png';

const FooterContainer = styled.div`
    display: flex;
    background-color: #00012C;
    padding: 2.5em;
    justify-content: space-around;
    h2{
        font-size: 18px;
    }
    p{
        font-size: 14px;
        color: white;
    }
    a{
        text-decoration: none;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const LeftSideContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 60vw;
    div{
        width: 90%;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
`;

const ProdutosContainer = styled.div`
    h2{
        color: #F17900;
        border-bottom: 0.0625em solid #F17900;
    }
`;

const AtendimentoContainer = styled.div`
    h2{
        color: #F200A2;
        border-bottom: 0.0625em solid #F200A2;
    }
`;

const ContatosContainer = styled.div`
    div{
        display: flex;
        gap: 0.125em;
        align-items: center;
    }
    h2{
        color: #FFCC00;
        border-bottom: 0.0625em solid #FFCC00;
    }
    p{
        margin: 0;
    }
`;

const GraficaContainer = styled.div`
    display: flex;
    flex-direction: column;
    h2{
        color: #0077FF;
        border-bottom: 0.0625em solid #0077FF;
    }
    a{
        text-decoration: none;
        color: white;
        &:hover{
            color: gray;
        }
    }
`;

const PagamentoContainer = styled.div`
    h2{
        color: #BBDC00;
        border-bottom: 0.0625em solid #BBDC00;
    }
    img{
        padding: 0;
        margin: 0;
        width: 100%;
    }
`;

const RedesContainer = styled.div`
    h2{
        color: #F200A2;
        border-bottom: 0.0625em solid #F200A2;
    }
    div{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        @media screen and (max-width: 1024px){
           justify-content: left;
           gap: 0.5em;
        }
    }
`;

const RightSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 40%;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
`;

const GraficaLogoContainer = styled.div`
    img{
        width: 80%;
    }
`;

const CopyrightContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: #FFFFFF; 
    text-align: center;
    align-items: center;
    h1 {
        font-size: 16px;
    }
    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const DesktopCopyrightContainer = styled.div`
    flex-direction: column;
    color: #FFFFFF;
    align-items: center;
    div {
        display: flex;
        margin-top: 2em;
    }
    h1 {
        font-size: 16px;
        padding: 0;
        margin: 0;
    }
    img {
        margin-left: 0.25em;
    }
    p {
        align-items: left;
    }
    @media screen and (max-width: 1024px) {
        div {
            display: none;
            
        }
        p {
            display: none;
        }
    }
`;

function Footer() {
    return (
        <FooterContainer>
            <LeftSideContainer>
                <div>
                    <ProdutosContainer>
                        <h2>PRODUTOS</h2>
                        <p>Artigos Personalizados | Adesivo Automotivo | Fotos | Sublimação | DTF | Banner | Wind Banner | Tags | Impressão | Xerox | Cartão de Visita | Flyers
                            | Eletrônicos | Mimos | Etiquetas | Receituário </p>
                    </ProdutosContainer>
                    <AtendimentoContainer>
                        <h2>ATENDIMENTO</h2>
                        <p>Segunda À sexta - 9:00/12:00 - 13:30/18:00
                            Sábado - 9:00/13:00</p>
                    </AtendimentoContainer>
                    <ContatosContainer>
                        <h2>CONTATOS</h2>
                        <div>
                            <img src={miniWhatsapp} alt='' />
                            <a href='https://wa.me/555333423330'><p>(53) 3342-3330</p></a>

                        </div>
                        <div>
                            <img src={miniInstagram} alt='' />
                            <a href='https://www.instagram.com/mgtekinformaticaegrafica'><p>@mgtekinformaticaegrafica</p></a>
                        </div>
                        <div>
                            <img src={miniEmail} alt='' />
                            <p>mgtekinformaticaegrafica@gmail.com</p>
                        </div>
                    </ContatosContainer>
                    <DesktopCopyrightContainer>
                        <div>
                            <h1>Desenvolvido por</h1>
                            <img src={fusionLogo} alt='' />
                        </div>
                        <p>&copy; 2024 Todos os direitos reservados.</p>
                    </DesktopCopyrightContainer>
                </div>
                <div>
                    <GraficaContainer>
                        <h2>GRÁFICA MG-TEK</h2>
                        <a href='/'>Home</a>
                        <a href='/sobre'>Sobre</a>
                        <a href='/contatos'>Contatos</a>
                        <a href='/produtos'>Produtos</a>
                        <a href='/mais-vendidos'>Mais vendidos</a>
                        <a href='/faq'>Dúvidas frequentes</a>
                    </GraficaContainer>
                    <PagamentoContainer>
                        <h2>FORMAS DE PAGAMENTO</h2>
                        <img src={pagamentoImage} alt='' />
                    </PagamentoContainer>
                </div>
                <div>
                    <RedesContainer>
                        <h2>NOSSAS REDES</h2>
                        <div>
                            <a href='https://www.instagram.com/mgtekinformaticaegrafica'><img src={instagramLogo} alt='' /></a>
                            <a href='https://www.facebook.com/profile.php?id=100087001981452&mibextid=kFxxJD'><img src={facebookLogo} alt='' /></a>
                            <a href='https://wa.me/555333423330'><img src={whatsappLogo} alt='' /></a>
                            <img src={emailLogo} alt='' />
                        </div>
                    </RedesContainer>
                </div>
            </LeftSideContainer>
            <RightSideContainer>
                <GraficaLogoContainer>
                    <img src={mgtekLogo} alt='' />
                </GraficaLogoContainer>
            </RightSideContainer>
            <CopyrightContainer>
                <div>
                    <h1>Desenvolvido por</h1>
                    <img src={fusionLogo} alt='' />
                </div>
                <p>&copy; 2024 Todos os direitos reservados.</p>
            </CopyrightContainer>
        </FooterContainer>
    );
}

export default Footer;