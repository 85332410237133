import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import carouselImage1 from '../assets/images/carousel-1.png';
import carouselImage2 from '../assets/images/carousel-2.png';
import carouselImage3 from '../assets/images/carousel-3.png';
import carouselImage4 from '../assets/images/carousel-4.png';
import carouselImage5 from '../assets/images/carousel-5.png';
import carouselImage6 from '../assets/images/carousel-6.png';
import carouselImage7 from '../assets/images/carousel-7.png';
import carouselImage8 from '../assets/images/carousel-8.png';
import carouselImage9 from '../assets/images/carousel-9.png';

import mobileCarouselImage1 from '../assets/images/home-mobile-carousel-img-1.png';
import mobileCarouselImage2 from '../assets/images/home-mobile-carousel-img-2.png';
import mobileCarouselImage3 from '../assets/images/home-mobile-carousel-img-3.png';
import mobileCarouselImage4 from '../assets/images/home-mobile-carousel-img-4.png';
import mobileCarouselImage5 from '../assets/images/home-mobile-carousel-img-5.png';
import mobileCarouselImage6 from '../assets/images/home-mobile-carousel-img-6.png';
import mobileCarouselImage7 from '../assets/images/home-mobile-carousel-img-7.png';
import mobileCarouselImage8 from '../assets/images/home-mobile-carousel-img-8.png';
import mobileCarouselImage9 from '../assets/images/home-mobile-carousel-img-9.png';

import queridinhaImage from '../assets/images/queridinha-img.png';
import queridinhaMobileImage from '../assets/images/queridinha-mobile-img.png';


const HomePageContainer = styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
    img{
        width: 100%;
        object-fit: cover;
    }
`;

const QueridinhaContainer = styled.div`
    padding: 6.25em 12.5em;
    @media screen and (max-width: 1024px) {
        padding: 2.5em;
        content: url(${queridinhaMobileImage});
        width: 80%;
    }
`;

const Carousel1 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage1});
        width: 100%;
    }
`;

const Carousel2 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage2});
        width: 100%;
    }
`;

const Carousel3 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage3});
        width: 100%;
    }
`;

const Carousel4 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage4});
        width: 100%;
    }
`;

const Carousel5 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage5});
        width: 100%;
    }
`;

const Carousel6 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage6});
        width: 100%;
    }
`;

const Carousel7 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage7});
        width: 100%;
    }
`;

const Carousel8 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage8});
        width: 100%;
    }
`;

const Carousel9 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage9});
        width: 100%;
    }
`;

function HomePage() {
    return (
        <HomePageContainer>
            <Swiper
                slidesPerView={1}
                pagination={{ clickable: true }}
                navigation
            >
                <SwiperSlide>
                    <Carousel1>
                        <img src={carouselImage1} alt='' />
                    </Carousel1>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel2>
                        <img src={carouselImage2} alt='' />
                    </Carousel2>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel3>
                        <img src={carouselImage3} alt='' />
                    </Carousel3>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel4>
                        <img src={carouselImage4} alt='' />
                    </Carousel4>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel5>
                        <img src={carouselImage5} alt='' />
                    </Carousel5>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel6>
                        <img src={carouselImage6} alt='' />
                    </Carousel6>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel7>
                        <img src={carouselImage7} alt='' />
                    </Carousel7>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel8>
                        <img src={carouselImage8} alt='' />
                    </Carousel8>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel9>
                        <img src={carouselImage9} alt='' />
                    </Carousel9>
                </SwiperSlide>
            </Swiper>
            <QueridinhaContainer>
                <img src={queridinhaImage} alt='' />
            </QueridinhaContainer>

        </HomePageContainer>
    );
}

export default HomePage;