import styled from 'styled-components';
import whatsappImage from '../../assets/images/zap-logo.png';

const StyledWhatsapp = styled.div`
    img{
        position: fixed;
        bottom: 1.25em;
        right: 1.25em;
        width: 10%;
        height: auto;
        z-index: 1000;

        @media screen and (max-width: 1024px){
            width: 20%;
            right: 0.625em;
            bottom: 1.5em;
        }
    }

`;

function WhatsappIcon() {
    return (
        <StyledWhatsapp>
            <a href='https://wa.me/555333423330'>
                <img src={whatsappImage} alt='' />
            </a>
        </StyledWhatsapp>
    );
}

export default WhatsappIcon;