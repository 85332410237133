import contactBanner from '../assets/images/contact-left-banner.png';

import instagramLink from '../assets/images/contact-instagram-link.png';
import emailLink from '../assets/images/contact-email-link.png';
import whatsappLink from '../assets/images/contact-whatsapp-link.png';
import facebookLink from '../assets/images/contact-facebook-link.png';

import ladyImage from '../assets/images/contact-lady-img.png';
import ladyImageMobile from '../assets/images/contact-lady-mobile-img.png';
import bottomImage from '../assets/images/contact-bottom-img.png';
import bottomImageMobile from '../assets/images/contact-bottom-mobile-img.png';
import styled from 'styled-components';

const ContactPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const UpperContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        img{
            width: 100%;
        }
    }
`;

const UpperLeftContainer = styled.div`
`;

const UpperRightContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        padding: 0 1em;
    }
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const BottomTitleContainer = styled.div`
    text-align: center;
    color: #ffffff;
    background-color: #030450;
    font-size: 24px;

    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
`;

const BottomImagesContainer = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 2em 0.25em;
    img{
        width: 100%;
    }
    @media screen and (max-width: 1024px) {
        padding: 1em 0.25em;
    }
`;

const LadyImageContainer = styled.div`
    @media screen and (max-width: 1024px) {
        img{
            content: url(${ladyImageMobile});
        }
    }
`;

const BottomImageContainer = styled.div`
    @media screen and (max-width: 1024px) {
        img{
            padding-top: 1.5em;
            content: url(${bottomImageMobile});
        }
    }
`;

function ContactPage() {
    return (
        <ContactPageContainer>
            <UpperContainer>
                <UpperLeftContainer>
                    <img src={contactBanner} alt='' />
                </UpperLeftContainer>
                <UpperRightContainer>
                    <a href='https://www.instagram.com/mgtekinformaticaegrafica'><img src={instagramLink} alt='' /></a>
                    <img src={emailLink} alt='' />
                    <a href='https://wa.me/555333423330'><img src={whatsappLink} alt='' /></a>
                    <a href='https://www.facebook.com/profile.php?id=100087001981452&mibextid=kFxxJD'><img src={facebookLink} alt='' /></a>
                </UpperRightContainer>
            </UpperContainer>
            <BottomContainer>
                <BottomTitleContainer>
                    <h2>Estamos prontos para transformar suas ideias em impressões de qualidade!</h2>
                </BottomTitleContainer>
                <BottomImagesContainer>
                    <LadyImageContainer>
                        <img src={ladyImage} alt='' />
                    </LadyImageContainer>
                    <BottomImageContainer>
                        <img src={bottomImage} alt='' />
                    </BottomImageContainer>
                </BottomImagesContainer>
            </BottomContainer>
        </ContactPageContainer>
    );
}

export default ContactPage;