import { Link } from "react-router-dom";
import styled from "styled-components";

import homeLogo from '../../assets/images/home-logo.png';
import bagLogo from '../../assets/images/bag-logo.png';
import phoneLogo from '../../assets/images/phone-logo.png';
import starLogo from '../../assets/images/star-logo.png';
import infoLogo from '../../assets/images/info-logo.png';
import faqLogo from '../../assets/images/faq-logo.png';

import homeLogoSelected from '../../assets/images/home-logo-selected.png';
import bagLogoSelected from '../../assets/images/bag-logo-selected.png';
import phoneLogoSelected from '../../assets/images/phone-logo-selected.png';
import starLogoSelected from '../../assets/images/star-logo-selected.png';
import infoLogoSelected from '../../assets/images/info-logo-selected.png';
import faqLogoSelected from '../../assets/images/faq-logo-selected.png';

// import backgroundImage1 from '../../assets/images/nav-background-1.png';
// import backgroundImage2 from '../../assets/images/nav-background-2.png';
// import backgroundImage3 from '../../assets/images/nav-background-3.png';
// import backgroundImage4 from '../../assets/images/nav-background-4.png';
// import backgroundImage5 from '../../assets/images/nav-background-5.png';
// import backgroundImage6 from '../../assets/images/nav-background-6.png';

const NavigationContainer = styled.div`
    border-top: 0.4em solid transparent;
    border-image: linear-gradient(to right, #813FBB, #0584FA, #FFDB49, #FF54C6, #FCA34A, #D9ED6B) 1;
    padding: 0.75em 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    a{
        text-decoration: none;
        color: black;
    }
`;

const HomeContainer = styled.div`
    padding-left: 2.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    &:hover{
        color: #813FBB;
        img{
            content: url(${homeLogoSelected});
        }
    }
    @media screen and (max-width: 1024px) {
        gap: 0;
        padding-left: 1em;
        img{
            width: 70%;
        }
    }
`;

const ProdutosContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    &:hover{
        color: #0584FA;
        img{
            content: url(${bagLogoSelected});
        }
    }
    @media screen and (max-width: 1024px) {
        gap: 0;
        img{
            width: 70%;
        }
    }
`;

const ContatosContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    &:hover{
        color: #FFDB49;
        img{
            content: url(${phoneLogoSelected});
        }
    }
    @media screen and (max-width: 1024px) {
        gap: 0;
        img{
            width: 70%;
        }
    }
`;

const MaisVendidosContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    &:hover{
        color: #FF54C6;
        img{
            content: url(${starLogoSelected});
        }
    }
    @media screen and (max-width: 1024px) {
        gap: 0;
        img{
            width: 70%;
        }
    }
`;

const SobreContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    &:hover{
        color: #FCA34A;
        img{
            content: url(${infoLogoSelected});
        }
    }
    @media screen and (max-width: 1024px) {
        gap: 0;
        img{
            width: 70%;
        }
    }
`;

const FaqContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    padding-right: 2.25em;
    gap: 1em;
    &:hover{
        color: #D9ED6B;
        img{
            content: url(${faqLogoSelected});
        }
    }
    @media screen and (max-width: 1024px) {
        gap: 0;
        padding-right: 1em;
        img{
            width: 70%;
        }
    }
`;

const LinkText = styled.p`
    font-size: 24px;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

function Navigation() {
    return (
        <NavigationContainer>
            <Link to='/'>
                <HomeContainer>
                    <img src={homeLogo} alt='' />
                    <LinkText>HOME</LinkText>
                </HomeContainer>
            </Link>
            <Link to='/produtos'>
                <ProdutosContainer>
                    <img src={bagLogo} alt='' />
                    <LinkText>NOSSOS PRODUTOS</LinkText>
                </ProdutosContainer>
            </Link>
            <Link to='/contatos'>
                <ContatosContainer>
                    <img src={phoneLogo} alt='' />
                    <LinkText>FALE CONOSCO</LinkText>
                </ContatosContainer>
            </Link>
            <Link to='/mais-vendidos'>
                <MaisVendidosContainer>
                    <img src={starLogo} alt='' />
                    <LinkText>MAIS VENDIDOS</LinkText>
                </MaisVendidosContainer>
            </Link>
            <Link to='/sobre'>
                <SobreContainer>
                    <img src={infoLogo} alt='' />
                    <LinkText>SOBRE NÓS</LinkText>
                </SobreContainer>
            </Link>
            <Link to='/faq'>
                <FaqContainer>
                    <img src={faqLogo} alt='' />
                    <LinkText>DÚVIDAS FREQUENTES</LinkText>
                </FaqContainer>
            </Link>
        </NavigationContainer>
    );
}

export default Navigation;