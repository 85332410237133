import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import carouselImage1 from '../assets/images/best-selling-carousel-img-1.png';
import carouselImage2 from '../assets/images/best-selling-carousel-img-2.png';
import carouselImage3 from '../assets/images/best-selling-carousel-img-3.png';
import carouselImage4 from '../assets/images/best-selling-carousel-img-4.png';
import carouselImage5 from '../assets/images/best-selling-carousel-img-5.png';
import carouselImage6 from '../assets/images/best-selling-carousel-img-6.png';

import mobileCarouselImage1 from '../assets/images/best-selling-mobile-carousel-img-1.png';
import mobileCarouselImage2 from '../assets/images/best-selling-mobile-carousel-img-2.png';
import mobileCarouselImage3 from '../assets/images/best-selling-mobile-carousel-img-3.png';
import mobileCarouselImage4 from '../assets/images/best-selling-mobile-carousel-img-4.png';
import mobileCarouselImage5 from '../assets/images/best-selling-mobile-carousel-img-5.png';
import mobileCarouselImage6 from '../assets/images/best-selling-mobile-carousel-img-6.png';

import mainBanner from '../assets/images/best-selling-main-banner.png';
import mainMobileBanner from '../assets/images/best-selling-mobile-banner.png';

const BestSellingPageContainer = styled.div`
    width: 100%;
    margin-bottom: 6.25em;
    img{
        width: 100%;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 2em;
    }
`;

const MainBannerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.75em;
    img{
        width: 90%;
    }

    @media screen and (max-width: 1024px){
        content: url(${mainMobileBanner});
        width: 100%;
    }
`;

const BottomTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h2{
        font-size: 40px;
        padding: 0;
        margin: 0.5em;
    }
    a{
        display: flex;
        justify-content: center;
        background-color: #4B0085;
        padding: 0 5em;
        border-radius: 100px;
        text-decoration: none;
        align-items: center;
        h2{
            &:hover{
                color: #D8BBEE;
            }
            color: #FFFFFF;
            font-size: 70px;
        }
    }
    @media screen and (max-width: 1024px){
        h2{
            font-size: 20px;
        }
        a{
            padding: 0 2em;
            h2{
                font-size: 42px;
            }
        }
    }
`;

const Carousel1 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage1});
        width: 100%;
    }
`;

const Carousel2 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage2});
        width: 100%;
    }
`;

const Carousel3 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage3});
        width: 100%;
    }
`;

const Carousel4 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage4});
        width: 100%;
    }
`;

const Carousel5 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage5});
        width: 100%;
    }
`;

const Carousel6 = styled.div`
    @media screen and (max-width: 1024px){
        content: url(${mobileCarouselImage6});
        width: 100%;
    }
`;

function BestSellingPage() {
    return (
        <BestSellingPageContainer>
            <Swiper
                slidesPerView={1}
                pagination={{ clickable: true }}
                navigation
            >
                <SwiperSlide>
                    <Carousel1>
                        <img src={carouselImage1} alt='' />
                    </Carousel1>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel2>
                        <img src={carouselImage2} alt='' />
                    </Carousel2>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel3>
                        <img src={carouselImage3} alt='' />
                    </Carousel3>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel4>
                        <img src={carouselImage4} alt='' />
                    </Carousel4>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel5>
                        <img src={carouselImage5} alt='' />
                    </Carousel5>
                </SwiperSlide>
                <SwiperSlide>
                    <Carousel6>
                        <img src={carouselImage6} alt='' />
                    </Carousel6>
                </SwiperSlide>
            </Swiper>
            <MainBannerContainer>
                <img src={mainBanner} alt='' />
            </MainBannerContainer>
            <BottomTextContainer>
                <h2>FICOU INTERESSADO EM SABER MAIS SOBRE NOSSOS PRODUTOS?</h2>
                <h2>CLIQUE EM “SAIBA MAIS ”!!</h2>
                <a href='https://wa.me/555333423330'>
                    <h2>SAIBA MAIS</h2>
                </a>
            </BottomTextContainer>
        </BestSellingPageContainer>
    );
}

export default BestSellingPage;