import styled from "styled-components";

const CreateSiteContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
`;

const LinkContainer = styled.a`
    text-decoration: none;
    color: #FFFFFF;
    padding: 0.5em 10em;
    background: linear-gradient(90deg, #FA575D, #DF5686, #B454C5, #8F52FA);
    border-radius: 6em;
    animation: pulsate 2s infinite ease-in-out, gradientShift 3s infinite linear;
    @keyframes pulsate {
        0% {
        transform: scale(1);
        }
        50% {
        transform: scale(1.05);
        }
        100% {
        transform: scale(1);
        }
    }

    @keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
    }

    @media screen and (max-width: 1024px) {
        width: 80%;
        padding: 0.5em 1em;
    }
`;

const TitleContainer = styled.h2`
    font-size: 56px;
    text-align: center;

    @media screen and (max-width: 1024px) {
        font-size: 24px;
    }
`;

function CreateSite() {
    return (
        <CreateSiteContainer>
            <LinkContainer href="https://wa.me/555333423330">
                <TitleContainer>CRIAMOS SEU SITE</TitleContainer>
            </LinkContainer>
        </CreateSiteContainer>
    );
}

export default CreateSite;