import styled from 'styled-components';
import { Link } from 'react-router-dom';

import clockImage from '../../assets/images/header-new-clock.png';
import pinImage from '../../assets/images/header-new-pin.png';
import mgtekLogo from '../../assets/images/mgtek-logo.png';

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: linear-gradient(to right, 
        rgba(75, 0, 133, 0.3), 
        rgba(0, 111, 215, 0.3), 
        rgba(255, 204, 0, 0.3), 
        rgba(242, 0, 162, 0.3), 
        rgba(241, 121, 0, 0.3), 
        rgba(187, 220, 0, 0.3)
    );
`;

const LeftSideContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 1em 2em;
    img{
        width: 100%;
    }
    @media screen and (max-width: 1024px){
        padding: 0.75em;
    }
`;

const RightSideContainer = styled.div`
    display: flex;
    padding: 0.625em 2.25em;
    align-items: center;
    justify-content: space-around;
    gap: 1.5em;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 0;
        padding-right: 0;
    }
`;

const PinContainer = styled.div`
    display: flex;
    img{
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        img{
            width: 90%;
        }
    }
`;

const ClockContainer = styled.div`
    display: flex;
    img{
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        img{
            width: 90%;
        }
    }
`;

function Header() {
    return (
        <HeaderContainer>
            <LeftSideContainer>
                <Link to='/'>
                    <img src={mgtekLogo} alt='' />
                </Link>
            </LeftSideContainer>
            <RightSideContainer>
                <ClockContainer>
                    <img src={clockImage} alt='' />
                </ClockContainer>
                <PinContainer>
                    <img src={pinImage} alt='' />
                </PinContainer>
            </RightSideContainer>
        </HeaderContainer>
    );
}

export default Header;